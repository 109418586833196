




































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldAddress from '@/components/FieldAddress.vue';
import PICKUP from '@/graphql/queries/PickupLocation.graphql';
import PICKUP_CREATE from '@/graphql/mutations/PickupLocationCreate.graphql';
import PICKUP_UPDATE from '@/graphql/mutations/PickupLocationUpdate.graphql';
import { required, decimal } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import numberOrNull from '@/utils/numberOrNull';
import { CODES } from '@/utils/constants';
import {
  PickupLocation,
  PickupLocationCreateInput,
  PickupLocationCreateMutationVariables,
  PickupLocationQueryVariables,
  PickupLocationUpdateMutationVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminFarmsAdd',
  components: {
    AppMain,
    AppForm,
    FieldAddress,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        name: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: '',
          notes: '',
          latitude: null,
          longitude: null,
        },
      } as PickupLocationCreateInput,
      pickup: {} as PickupLocation,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        address: {
          street: { required },
          city: { required },
          state: { required },
          zip: { required },
          latitude: { decimal },
          longitude: { decimal },
        },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(pickup: PickupLocation) {
      if (!pickup) return;
      this.form = {
        name: pickup.name || '',
        address: {
          street: pickup.address ? pickup.address.street : '',
          city: pickup.address ? pickup.address.city : '',
          state: pickup.address ? pickup.address.state : '',
          zip: pickup.address ? pickup.address.zip : '',
          notes: pickup.address ? pickup.address.notes || '' : '',
          latitude: pickup.address ? pickup.address.latitude || null : null,
          longitude: pickup.address ? pickup.address.longitude || null : null,
        },
      };
    },
    async savePickup() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updatePickup();
        else await this.addPickup();
        this.$router.replace({ name: 'admin-farms' });
        this.$notify({
          text: 'Farm saved!',
          type: 'success',
          duration: 6000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    addPickup() {
      return this.$apollo.mutate({
        mutation: PICKUP_CREATE,
        variables: {
          input: {
            name: this.form.name,
            address: {
              street: this.form.address.street,
              city: this.form.address.city,
              state: this.form.address.state,
              zip: this.form.address.zip,
              notes: this.form.address.notes,
              latitude: numberOrNull(this.form.address.latitude),
              longitude: numberOrNull(this.form.address.longitude),
            },
          },
        } as PickupLocationCreateMutationVariables,
      });
    },
    updatePickup() {
      return this.$apollo.mutate({
        mutation: PICKUP_UPDATE,
        variables: {
          input: {
            id: this.pickup.id,
            name: this.form.name,
            address: {
              street: this.form.address.street,
              city: this.form.address.city,
              state: this.form.address.state,
              zip: this.form.address.zip,
              notes: this.form.address.notes,
              latitude: numberOrNull(this.form.address.latitude),
              longitude: numberOrNull(this.form.address.longitude),
            },
          },
        } as PickupLocationUpdateMutationVariables,
      });
    },
  },
  apollo: {
    pickup: {
      query: PICKUP,
      variables(): PickupLocationQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ pickup: PickupLocation }>) {
        if (data) this.fillForm(data.pickup);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-farms' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
    },
  },
});
